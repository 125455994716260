// Copyright 2022 The LUCI Authors.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import { Fragment } from 'react';

import CLList from '@/clusters/components/cl_list/cl_list';
import {
  ExoneratedTestVariant,
  ExonerationCriteria,
} from '@/clusters/components/cluster/cluster_analysis_section/exonerations_tab/model/model';
import {
  invocationName,
  failureLink,
} from '@/clusters/tools/urlHandling/links';
import { RelativeTimestamp } from '@/common/components/relative_timestamp';
import { displayApproxDuartion } from '@/common/tools/time_utils';
import { TestVariantFailureRateAnalysis_VerdictExample } from '@/proto/go.chromium.org/luci/analysis/proto/v1/test_variants.pb';

import { ExplanationChip } from '../explanation_chip/explanation_chip';

interface Props {
  criteria: ExonerationCriteria;
  testVariant: ExoneratedTestVariant;
}

const FlakyCriteriaSection = ({ criteria, testVariant }: Props) => {
  const chips = [];
  if (criteria.runFlakyVerdicts1wd > 0) {
    chips.push(
      <ExplanationChip
        value={testVariant.runFlakyVerdicts1wd}
        threshold={criteria.runFlakyVerdicts1wd}
        text="Run-flaky verdicts in the last weekday"
        testId="flaky_verdicts_1wd"
      />,
    );
  }
  if (criteria.runFlakyVerdicts5wd > 0) {
    chips.push(
      <ExplanationChip
        value={testVariant.runFlakyVerdicts5wd}
        threshold={criteria.runFlakyVerdicts5wd}
        text="Run-flaky verdicts in the last five weekdays"
        testId="flaky_verdicts_5wd"
      />,
    );
  }
  if (criteria.runFlakyPercentage1wd > 0) {
    chips.push(
      <ExplanationChip
        value={testVariant.runFlakyPercentage1wd}
        threshold={criteria.runFlakyPercentage1wd}
        text="Flaky rate percentage last weekday"
        testId="flaky_percentage_1wd"
      />,
    );
  }
  return (
    <>
      <Typography variant="h6">Purpose</Typography>
      <Typography paragraph>
        Exonerates test variants that are so flaky that (inexpensive) retries
        are no longer effective at mitigating failures.
      </Typography>
      <Typography variant="h6">Definition</Typography>
      <Typography component="div" paragraph>
        {chips.map((chip, i) => {
          return (
            <Fragment key={i}>
              {i > 0 ? <>&nbsp;AND&nbsp;</> : null}
              {chip}
            </Fragment>
          );
        })}
        &nbsp;.
      </Typography>
      <Typography component="div">
        Where:
        <ul>
          <li>
            <strong>Run-flaky verdicts</strong>: Verdicts which required a
            step-level (i.e. swarming task-level) retry to obtain an expected
            test result, filtered to:
            <ul>
              <li>at most one verdict per distinct changelist</li>
              <li>
                exclude changelists generated by automation (e.g. autorollers)
              </li>
            </ul>
          </li>
          <li>
            <strong>Weekday</strong>: An interval of at least 24 hours, which
            starts at a given time on a calendar weekday and ends at the same
            time on the following calendar weekday. Includes the intervening
            weekend (if any).
          </li>
        </ul>
      </Typography>
      <Typography variant="h6">Recent run-flaky verdicts</Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Invocation</TableCell>
            <TableCell>Changelist and patchset</TableCell>
            <TableCell>Timestamp</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {testVariant.runFlakyVerdictExamples.map(
            (
              verdict: TestVariantFailureRateAnalysis_VerdictExample,
              i: number,
            ) => {
              return (
                <TableRow key={i.toString()}>
                  <TableCell>
                    <Link
                      aria-label="invocation id"
                      sx={{ mr: 2 }}
                      href={failureLink(
                        verdict.ingestedInvocationId,
                        testVariant.testId,
                      )}
                      target="_blank"
                    >
                      {invocationName(verdict.ingestedInvocationId)}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <CLList changelists={verdict.changelists || []}></CLList>
                  </TableCell>
                  <TableCell>
                    <RelativeTimestamp
                      formatFn={displayApproxDuartion}
                      timestamp={DateTime.fromISO(verdict.partitionTime || '')}
                    />
                  </TableCell>
                </TableRow>
              );
            },
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default FlakyCriteriaSection;
