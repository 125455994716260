// Copyright 2022 The LUCI Authors.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { createContext } from 'react';

// ClusterId represents a fully-qualified cluster identifier.
export interface ClusterId {
  project: string;
  algorithm: string;
  id: string;
}

export const ClusterContext = createContext<ClusterId>({
  project: '',
  algorithm: '',
  id: '',
});

interface Props {
  project: string;
  clusterAlgorithm: string;
  clusterId: string;
  children: React.ReactNode;
}

export const ClusterContextProvider = ({
  project,
  clusterAlgorithm,
  clusterId,
  children,
}: Props) => {
  return (
    <ClusterContext.Provider
      value={{ project: project, algorithm: clusterAlgorithm, id: clusterId }}
    >
      {children}
    </ClusterContext.Provider>
  );
};
