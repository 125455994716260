// Copyright 2022 The LUCI Authors.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';

import CLList from '@/clusters/components/cl_list/cl_list';
import {
  ExoneratedTestVariant,
  ExonerationCriteria,
} from '@/clusters/components/cluster/cluster_analysis_section/exonerations_tab/model/model';
import {
  invocationName,
  failureLink,
} from '@/clusters/tools/urlHandling/links';
import { RelativeTimestamp } from '@/common/components/relative_timestamp';
import { displayApproxDuartion } from '@/common/tools/time_utils';
import { TestVariantFailureRateAnalysis_RecentVerdict } from '@/proto/go.chromium.org/luci/analysis/proto/v1/test_variants.pb';

import { ExplanationChip } from '../explanation_chip/explanation_chip';

interface Props {
  criteria: ExonerationCriteria;
  testVariant: ExoneratedTestVariant;
}

const FailureCriteriaSection = ({ criteria, testVariant }: Props) => {
  return (
    <>
      <Typography variant="h6">Purpose</Typography>
      <Typography paragraph>
        Exonerates test variants with a high failure rate (from approximately
        70% up to 100%), e.g. tests broken at tip-of-tree.
      </Typography>
      <Typography variant="h6">Definition</Typography>
      <Typography component="div" paragraph>
        <ExplanationChip
          value={testVariant.recentVerdictsWithUnexpectedRuns}
          threshold={criteria.recentVerdictsWithUnexpectedRuns}
          text="Recent verdicts with unexpected runs"
          testId="unexpected_verdict_count"
        />
        &nbsp;.
      </Typography>
      <Typography component="div">
        Where:
        <ul>
          <li>
            <strong>Recent verdicts</strong>: The 10 most recent verdicts for
            the test variant, filtered to:
            <ul>
              <li>at most one verdict per distinct changelist</li>
              <li>
                exclude changelists generated by automation (e.g. autorollers)
              </li>
            </ul>
          </li>
          <li>
            <strong>Unexpected test run</strong>: A swarming task for which all
            test results were unexpected (skips excluded).
          </li>
        </ul>
      </Typography>
      <Typography variant="h6">Recent verdicts</Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Invocation</TableCell>
            <TableCell>Result</TableCell>
            <TableCell>Changelist and patchset</TableCell>
            <TableCell>Timestamp</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {testVariant.recentVerdicts.map(
            (
              verdict: TestVariantFailureRateAnalysis_RecentVerdict,
              i: number,
            ) => {
              return (
                <TableRow key={i.toString()}>
                  <TableCell>
                    <Link
                      aria-label="invocation id"
                      sx={{ mr: 2 }}
                      href={failureLink(
                        verdict.ingestedInvocationId,
                        testVariant.testId,
                      )}
                      target="_blank"
                    >
                      {invocationName(verdict.ingestedInvocationId)}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Chip
                      color={verdict.hasUnexpectedRuns ? 'error' : 'success'}
                      variant="outlined"
                      size="small"
                      label={
                        verdict.hasUnexpectedRuns
                          ? 'Has unexpected run'
                          : 'No unexpected run'
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <CLList changelists={verdict.changelists || []}></CLList>
                  </TableCell>
                  <TableCell>
                    <RelativeTimestamp
                      formatFn={displayApproxDuartion}
                      timestamp={DateTime.fromISO(verdict.partitionTime || '')}
                    />
                  </TableCell>
                </TableRow>
              );
            },
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default FailureCriteriaSection;
